@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&family=Roboto:ital,wght@0,100;0,300;0,400;1,100&display=swap");
* {
  box-sizing: border-box;
}

body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #202020;
  background-image: radial-gradient(circle, #87b1df 60%, #28425c);
}

input[type="button"] {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-self: center;
  margin: auto;
  min-height: 95vh;
}

.header-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-bottom: 2em;
}

.home-link {
  text-decoration: none;
}

.stonks {
  color: rgb(1, 63, 1);
  margin: 0;
  font-family: "Roboto condensed";
  font-size: 46px;
  font-weight: 100;
}

.not-stonks {
  text-align: right;
  color: rgb(155, 1, 1);
  font-family: "Roboto condensed";
  font-size: 46px;
  margin: 0;
}

.search-container {
  width: 100%;
}

.search-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stonk-search-label {
  flex-grow: 1;
}

.stonk-search {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-bottom: #202020 solid 1px;
  background: none;
  width: 100%;
  max-width: 450px;
  font-size: 21px;
}

.stonk-search::placeholder {
  color: #404040;
}

#search-button {
  width: 140px;
  margin-left: 10px;
  background-color: #202020ee;
  border: none;
  font-size: 18px;
  font-weight: 300;
  color: #ccc;
  border-radius: 5px;
}

#search-button:disabled {
  background-color: #505050ee;
  color: #eee;
}

#search-button:enabled {
  background-color: #202020ee;
  color: #ccc;
}

.autofill-container {
  display: flex;
  background-color: #202020ee;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 450px;
  z-index: 1;
  position: absolute;
}

.autofill-result {
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  padding: 5px 0;
  font-weight: 100;
  letter-spacing: 0.75px;
  background-color: #202020ee;
  color: #ccc;
}

.home-container {
  padding: 2em 0;
  text-align: center;
}

.home-subhead {
  font-family: "Roboto condensed";
  font-size: 46px;
}

.home-text {
  font-size: 21px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
}

.loading {
  padding: 2em 0;
}
.stonk-container {
  padding: 2em 0;
  width: 100%;
}
.stonk-image {
  width: 100%;
}

.is-stonks {
  font-family: "Roboto condensed";
  font-size: 46px;
  text-align: center;
  margin: 0;
  padding: 10px 0;
}

.loss {
  color: rgb(155, 1, 1);
  text-align: center;
  font-size: 36px;
  margin: 0;
  padding: 0 0 20px 0;
}
.gain {
  color: rgb(1, 63, 1);
  text-align: center;
  font-size: 36px;
  margin: 0;
  padding: 0 0 20px 0;
}

.price-container {
  margin: auto;
  width: max-content;
}

.price-text {
  font-size: 21px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
}

.footer-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-items: self-end;
  text-align: center;
}

.footer-text {
  font-size: 14px;
  margin-bottom: 0;
}

.footer-link {
  color: #202020;
}

.footer-content {
  margin: auto auto 0 auto;
}

@media only screen and (max-width: 440px) {
  .header-container {
    width: 90%;
    padding-top: 2em;
  }

  .stonks {
    color: rgb(1, 63, 1);
    margin: 0;
    font-family: "Roboto condensed";
    font-size: 42px;
    font-weight: 100;
  }

  .not-stonks {
    text-align: right;
    color: rgb(155, 1, 1);
    font-family: "Roboto condensed";
    font-size: 42px;
    margin: 0;
  }

  .home-subhead {
    font-family: "Roboto condensed";
    font-size: 36px;
  }

  .home-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
  }
}
